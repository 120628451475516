@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
* {
  font-family: 'Nunito', sans-serif;
}

.square {
  background: #fff;

  float: left;
  font-size: 50px;
  font-weight: bold;
  padding: 0;
  text-align: center;
  width: 80px;
  line-height: 80px;
  height: 80px;
 cursor:pointer;
  text-decoration: none;
  border: none;
  font-weight: bold;
  outline: none;
  margin: 10px;

  border-radius: 0px;
  background: #ffffff;
  box-shadow: 5px 5px 9px #8f8f8f,
    -5px -5px 9px #ffffff;
}

.square:active {
  border-radius: 0px;
  background: #ffffff;
  box-shadow: inset 5px 5px 9px #8f8f8f,
  inset -5px -5px 9px #ffffff;
}


/* Board */
.board-row:after {
  clear: both;
  content: "";
  display: table;
}

/* Game */
.game {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Game Info */
ul {
  padding-left: 30px;
}

.game-info {
  margin-left: 20px;
}

.status {
  margin-bottom: 10px;
}
.game-info{
  font-size: 20px;
  margin-bottom: 20px;
}
.play-again-btn{
  background: #444444;
  color:white;
  cursor: pointer;
  border:none;
  margin-top:50px;
  padding:10px 20px;
  transition: all ease-in-out 0.5s;
}
.play-again-btn:hover{
  background: blue;
}